import React from 'react'
import {NavLink} from 'react-router-dom'

export const Payment = () => {
   
  return (
    <div className='maincontent'>
        <div className='loginform'>
        <h2>A few steps away from your Membership Card</h2>
      Select Bank:<select>
    <option></option>
      <option id='opt'>ICICI</option>
      <option id='opt'>HDFC</option>
      <option id='opt'>IOB</option>
      <option id='opt'>SBI</option>
    </select><br></br><br></br>
   Card Number:<input type="text" name="uname"required/><br></br><br></br>
    CVV:<input type="text" name="pass" maxLength={3} required/><br></br><br></br>
    Userid:<input type="text" name="uname"required/><br></br><br></br>
    Password:<input type="text" name="pass"required/><br></br><br></br>
    Amount:<input type="text" name="pass"required/><br></br><br></br>
    <button id="butt" onClick={() => alert("Payment Successful")}>
        Pay</button>
        <NavLink to="/user" exact><br></br><br></br>
        <button >Go To Profile</button>
      </NavLink>
    </div>
    </div>
  )
}
