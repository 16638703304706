import React from 'react'

export const Adv = (props) => {
  return (
    <div className='advert' >
        <h3>{props.content}</h3>
      <span className='imgadv'> <img src={props.img}/></span> 
    </div>
  )
}
