const books=[
   
{title:"The Alchemist",
    desc:'Paulo Coelho',
    image:"./images/alchemist.png"
},
{title:"Angels and Demons",
desc:'Dan Brown',
image:"images/angelsdemons.jpg"
},
{title:"The Blue Umbrella",
desc:'Ruskin Bond',
image:"images/blueum.jpg"
},
{title:"Two States",
desc:'Chetan Bhagat',
image:"images/twostates.jpg"
},
{title:"Ikigai",
desc:'Francesc Miralles',
image:"images/ikigai.jpg"
},
{
    title:"One Indian Girl",
    desc:'Chetan Bhagat',
    image:"images/oneindian.jpg"

},
{
    title:"The Monk who sold his Ferrari ",
    desc:'Ruskin Bond',
    image:"images/themonk.jpg"

},
{
    title:"Cinderella ",
    desc:'Walt Disney',
    image:"images/cinde.jpg"

},{
    title:"Ponniyin Selvan ",
    desc:'Kalki',
    image:"images/ponniyin.jpg"

},{
    title:"Harry Potter and the Deathly Hallows ",
    desc:'J K Rowling',
    image:"images/deathly.jpg"

},
{
    title:"The White Tiger ",
    desc:'Aravind Adiga',
    image:"images/whitetiger.jpg"

},
{
    title:"Panchatantra",
    desc:'for Children',
    image:"images/panch.jpg"

},


];
export default books;