import React from 'react'
import { Card } from '../components/Card';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Adv } from './Adv';



export const Home = () => {
  
  return (
    <div className='maincontent'>
    <div className='content'>
    {/*<img src={Annacent} alt='Annacent' height="500px" width="500px"></img>*/}
    <Slide autoplay={true}>
            <div className="each-slide-effect">
                <img src="images/anna1.jpg" height="400px" width="100%" />
                    
                
            </div>
            <div className="each-slide-effect">
            <img src="images/Annacent.jpg" height="400px" width="100%"/>
                    
               
            </div>
            <div className="each-slide-effect">
            <img src="images/anna2.jpg" height="400px" width="100%"/>
                    
            </div>
            <div className="each-slide-effect">
            <img src="images/anna3.jpg" height="400px" width="100%"/>
                   
            </div>
        </Slide>
    </div>
    <div className='leftcontent'>
    <h1 id="tt"><u>Featured Events:</u></h1>

      <div className='eventsshow'>
        <div className='datebox'>
          <div className='Month'>Aug
          </div>
            <div className='date'>
          <h3>22</h3></div>
          </div>
        
            <div className='eventtitle'>
        <span> Math and Science Day</span>
        <div className='eventbody'>
        <span>10:00am-5:00pm</span><br></br>
        <span>Red Auditorium</span>
        </div>
            </div>
      </div>


      <div className='eventsshow'>
        <div className='datebox'>
          <div className='Month'>Aug
          </div>
            <div className='date'>
          <h3>6</h3></div>
          </div>
        <div className='eventtitle'>
        <span> Work of Art-Artists Collaboration
          </span>
           <div className='eventbody'>
        <span >10:30am-1:00pm</span><br></br>
        <span>Riverview</span>
        </div>
            </div>
      </div>
      
      <div className='eventsshow'>
        <div className='datebox'>
          <div className='Month'>Sept
          </div>
            <div className='date'>
          <h3>5</h3></div>
          </div>
        <div className='eventtitle'>
        <span>One Book|Virtual Author Discussion</span>
           <div className='eventbody'>
        <span >7:30pm-9:00pm</span><br></br>
        <span>Online Event</span>
        </div>
            </div>
      </div>
      
      

      </div>


<div className='belowcontent'>
 <marquee direction='up' behaviour="alternate" ><div > <Adv 
  content="Be the first to read Chetan Bhagat's 'One Indian Girl'" img="images/oneindian.jpg"
  /></div></marquee>
  <marquee direction='down' behaviour="alternate"><div>
  <Adv
  content="Go get our Library Card! Membership plans starts with as low as 100/-" img="images/cardimage.png"/></div></marquee>
<div ><marquee direction='up' behaviour="alternate" onmouseover="stop()">
  <Adv content="Will you dare to read this Agastha Christie's work?" img="images/agastha.jpg"/>
  </marquee>
</div>

</div>
</div>
)
}
      {/*
      <div className='cardcontainer'>
        <div className='imagecontainer'>
          <img src={Annacent}/>
        </div>
        <div className='cardcontent'>
        <div className='card-title'>
          title
          </div>
          <div className='card-body'>
            body
          </div>
      </div>
  
</div>
*/}
    
