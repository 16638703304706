import React from 'react'

export const About = () => {
  return (
    <div className='maincontent'>
      <div className='ab'>
      <h2>About the Library</h2>
The Chennai Library is the cornerstone of our thriving city.

We welcome people of all ages and cultures, strengthen neighborhoods and communities, and connect all to a world of knowledge.
     <h2> Mission, Vision, and Values</h2>
Mission
We welcome all people to connect, learn, discover and grow.

Vision
We imagine a Saint Paul where all people feel seen, safe, and welcome. We imagine a city where libraries bring people together to experience hope, joy, and creativity through learning.

<h2>Library Experience Promise</h2>
We welcome you
​​​​​We listen with curiosity and create connections
We build spaces for likeness and difference
We practice anti-racism and racial healing work
We celebrate the joy of discovery
We are here because of you.
This is our Library Experience Promise.
      <h2>Borrowing Items</h2>
    Loan Periods
    
    Most library items can be checked out for 28 days (4 weeks).
    
    Mobile hotspots are checked out for 7 days (1 week).
    
    Book Club in a Bag kits are checked out for 6 weeks.
    
    Limits
    
    Up to 100 items may be checked out per library card.
    
    Limit for OverDrive e-books: 15
    
    <h2>Returning Items</h2>
    Items can be returned to any Saint Paul Public Library location.
    
    When a library is closed, please return items to after-hours book returns.
    
    Returning to Libraries Outside of Saint Paul
    
    You can return most items to any Twin Cities metropolitan area public library. The library will note the return date and send the items back to the Saint Paul Public Library. When they are received in Saint Paul, we will back-date the check-in to date noted by the other library. The items will still appear on your card until they are checked in at the Saint Paul Public Library.
    
    Exceptions
    
    Lucky Day books and DVDs must be returned to the library from which they were checked out.
    
    Items borrowed through Interlibrary Loan must be returned to a Saint Paul Public Library.
    
    
    </div>
    </div>
  )
}
