import React from 'react'
export const Card = (props) => {
  return (

    <div className='cardcontainer'>
        <div className='imagecontainer'>
          <img src={props.img}/>
        </div>
        <div className='cardcontent'>
        <div className='card-title'>
          {props.title}
          </div>
          <div className='card-body'>
            {props.body}
          </div>
          <button type='button' id='butt'>View</button>
      </div>
  
</div>
  )
}
