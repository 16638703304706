import React from 'react'
import {NavLink} from 'react-router-dom'


export const Navbarcontents = () => {
  return (
    <div className='Navbarcontents'>
      
      <h1 id="tit">The Chennai Library</h1>
      <ul>
    <li><NavLink to="/about" exact id='links'>About</NavLink></li>
     <li><NavLink to="/viewbooks" exact id='links'>Books</NavLink></li>
    <li><NavLink to="/registerlogin" exact id='links'>Register/Login</NavLink></li>
      <li><NavLink to="/" exact id='links'>Home</NavLink></li>
      
      
    </ul>
    
  </div>
  )
}
