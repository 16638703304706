import React from 'react'
import {NavLink} from 'react-router-dom'

export const Registration = () => {
  return (
    <div className='maincontent'>
    <div className='bg'>
    <div className='loginform'>
   <h2>Registration</h2>
   First Name:<input type="text" name="uname"/><br></br><br></br>
  Last Name:<input type="text" name="pass"/><br></br><br></br>
  Username:<input type="text" name="uname"/><br></br><br></br>
  Password:<input type="text" name="pass"/><br></br><br></br>
  <h3> Select Payment method:</h3><select>
    <option></option>
      <option id='opt'>UPI</option>
      <option id='opt'>Net Banking</option>
      <option id='opt'>Credit Card</option>
    </select><br></br><br></br>
  <NavLink to="/payment" exact>
        <button id="butt">Pay now & Register</button>
      </NavLink>
  </div>
  </div> 
  </div>
  )
}

;