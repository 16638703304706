import React from 'react'
import { Card } from './Card'
import books from './Bookdetails'

export const ViewBooks = () => {
function ncard(val){
return (
<Card
  img ={val.image}
  title={val.title}
  body={val.desc}
  />
  );
}
  
  return (
    <div className='maincontent'>

      <div className='header'>
   <h3> Select your Genre:</h3><select>
    <option></option>
      <option id='opt'>Thriller</option>
      <option id='opt'>Romance</option>
      <option id='opt'>Crime</option>
      <option id='opt'>Magazines</option>
    </select>
    </div>
<div className='cardlayout'>
 {books.map(ncard)}
{/*<Card
  img ={angelsdemons}
  title='The Alchemist'
  body='Desc'
  />
<Card
  img ={blueum}
  title='The Alchemist'
  body='Desc'
  />
<Card
  img ={ikigai}
  title='The Alchemist'
  body='Desc'
  />
<Card
  img ={themonk}
  title='The Alchemist'
  body='Desc'
  />
  <Card
  img ={twostates}
  title='The Alchemist'
  body='Desc'
  />
   <Card
  img ={alchemist}
  title='The Alchemist'
  body='Desc'
  />
<Card
  img ={angelsdemons}
  title='The Alchemist'
  body='Desc'
  />
<Card
  img ={blueum}
  title='The Alchemist'
  body='Desc'
  />
<Card
  img ={ikigai}
  title='The Alchemist'
  body='Desc'
  />
<Card
  img ={themonk}
  title='The Alchemist'
  body='Desc'
  />
  */}


</div>

    </div>
  )
}
