import React, { useState } from 'react'
import { Selectbook } from './Selectbook'
import books from './Bookdetails'

export const User = () => {
  function ncard(val){
    return (
    <Selectbook
      img ={val.image}
      title={val.title}
      body={val.desc}
      />
      );
    }
    
  return (
    <div className='maincontent'>
      <h2>Profile:</h2>
<div className='profile'>
        <div className='iss'>
        <h2>Issue</h2>
       Enter Book name:<input type="text" name="uname" /><br></br><br></br>
    Enter Book Id:<input type="text" name="pass"/><br></br><br></br>
    <button id="butt" onClick={() => alert("Please return the book in a week")}>
        Issue</button>
         </div>

        <div className='iss'>
          <h2>Return</h2>
          Enter Book name:<input type="text" name="uname"/><br></br><br></br>
    Enter Book Id:<input type="text" name="pass"/><br></br><br></br>
    <button id="butt" onClick={() => alert("Returned Successfully")}>
        Return</button>
        </div>
        
      
    </div>
    <div className='favourites'>
        <div className='cardlayout'>
        {books.map(ncard)}
        </div>
    </div>
    
    </div>
    
  );
};

