import React from 'react'
import {NavLink} from 'react-router-dom'



export const RegisterLogin = () => {
  return (
    <div className='maincontent'>
      <div className='bg'>
      <div className='loginform'>
     <h2>Login</h2>
    Username:<input type="text" name="uname"/><br></br><br></br>
    Password:<input type="text" name="pass"/><br></br><br></br>
    <NavLink to="/user" exact>
          <button id="butt">Login</button>
        </NavLink>
    <h3>
    <NavLink to="/registration" exact >New user? Sign up</NavLink>
    
    
    </h3>
    </div>
    </div> 
    </div>
  )
}
