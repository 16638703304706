import React from 'react'

export const Selectbook = (props) => {
    var name=props.title;
    
  return (
    <div className='cardcontainer'>
        <div className='imagecontainer'>
          <img src={props.img}/>
        </div>
        <div className='cardcontent'>
        <div className='card-title'>
          {props.title}
          </div>
          <div className='card-body'>
            {props.body}
          </div>
          <button type='button' id='butt' 
          onClick={()=>alert("To issue enter the book name and Id")}>Select</button>
      </div>
  
</div>
  )
}

