import React from 'react';
import {Routes, Route } from "react-router-dom";
import { Home } from './Home';
import { About } from './About';
import {ViewBooks} from './ViewBooks';
import { Navbarcontents } from './Navbarcontents';
import { RegisterLogin } from './RegisterLogin';
import { DigitalLib } from './DigitalLib';
import { User } from './User';
import {Registration} from './Registration';
import {Payment} from './Payment';
function Navbar () {
  return (
    <>
    <Navbarcontents/>
    <Routes>
    <Route path='/payment' element={<Payment />} />
    <Route path='/registration' element={<Registration/>}/>
    <Route path='/digitallib' element={<DigitalLib/>}/>
    <Route path='/viewbooks' element={<ViewBooks/>}/>
    <Route path='/registerlogin' element={<RegisterLogin/>}/>
    <Route path='/about' element={<About />} />
    <Route path='/' element={<Home/>}/>
    <Route path='/user' element={<User/>}/>
</Routes>
    </>
  );
};

export default Navbar;





{/*
<Route path='/registerlogin' element={<RegisterLogin/>}/>
<Link to="/registerlogin" exact>Register/Login</Link>

<Route path='/events' element={<Events/>}/>
<Link to="/events" exact>Events</Link>

<Route path='/digitallibrary' element={<DigitalLib/>}/>
<Link to="/digitallibrary" exact>Digital Library</Link>

<Route path='/about' element={<About />} />
<Link to="/about" exact>About</Link>
  */}
   